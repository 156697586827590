import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import Viewport from 'gatsby-theme-material-ui-top-layout/src/components/viewport.js'
import DashLayout from '../../components/dashibiden/DashLayout.js'

// create a default overide to add `enableColorScheme` property
//      see: https://www.npmjs.com/package/gatsby-theme-material-ui

// good documentation on the "provider" pattern: https://www.patterns.dev/posts/provider-pattern/

export default function TopLayout({ children, theme }) {
  return (
    <>
      <Viewport />
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline enableColorScheme />
        <DashLayout>
          {children}
        </DashLayout>
      </ThemeProvider>
    </>
  );
}
