import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles'
import CodeIcon from '@mui/icons-material/Code';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme()
  const backColor = { 'color': theme.palette.primary.main }

  return (
    <div>
      <IconButton onClick={handleClickOpen} aria-label="api" size="small" sx={backColor} title="Api">
        <ViewInArIcon fontSize="medium" />
      </IconButton>
      <IconButton onClick={handleClickOpen} aria-label="code" size="small" sx={backColor} title="Code">
        <CodeIcon fontSize="medium" />
      </IconButton>
      <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
              Développement en cours...
          </BootstrapDialogTitle>
          <DialogContent dividers>
              <Typography gutterBottom>
              Fonctionnalité en cours de développement. Prenez contact si vous souhaitez contribuer à la diffusion des ontologies RFDS.
              </Typography>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>
              Ok
              </Button>
          </DialogActions>
      </BootstrapDialog>
    </div>
  );
}