import React from 'react'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

export default function Copyright() {
  const startYear = '2021'
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        <Link color="inherit" href="https://mindmatcher.org/">
          MindMatcher.org
        </Link>{' - Build with love on open-souce tool `DashLod` - '}
        <Link color="inherit" href="https://gitlab.com/datalab-s/ontologies">
            Contribute on Gitlab
        </Link>
        {' - '}
        <Link color="inherit" href="http://apache.org/licenses/LICENSE-2.0">Apache License, Version 2.0</Link>
        {' - '}
        {`${startYear} -> ${new Date().getFullYear()}`}
      </Typography>
    );
  }
