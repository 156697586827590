exports.components = {
  "component---src-components-dashorm-dash-layout-concept-js": () => import("./../../../src/components/dashorm/DashLayoutConcept.js" /* webpackChunkName: "component---src-components-dashorm-dash-layout-concept-js" */),
  "component---src-components-dashorm-dash-layout-concept-scheme-js": () => import("./../../../src/components/dashorm/DashLayoutConceptScheme.js" /* webpackChunkName: "component---src-components-dashorm-dash-layout-concept-scheme-js" */),
  "component---src-components-dashorm-dash-layout-om-onto-index-js": () => import("./../../../src/components/dashorm/DashLayoutOmOntoIndex.js" /* webpackChunkName: "component---src-components-dashorm-dash-layout-om-onto-index-js" */),
  "component---src-components-dashorm-dash-layout-owl-ontology-js": () => import("./../../../src/components/dashorm/DashLayoutOwlOntology.js" /* webpackChunkName: "component---src-components-dashorm-dash-layout-owl-ontology-js" */),
  "component---src-components-dashorm-dash-layout-rdfs-datatype-js": () => import("./../../../src/components/dashorm/DashLayoutRdfsDatatype.js" /* webpackChunkName: "component---src-components-dashorm-dash-layout-rdfs-datatype-js" */),
  "component---src-components-dashorm-dash-layout-skos-collection-js": () => import("./../../../src/components/dashorm/DashLayoutSkosCollection.js" /* webpackChunkName: "component---src-components-dashorm-dash-layout-skos-collection-js" */),
  "component---src-components-dashorm-dash-tree-layout-6-js": () => import("./../../../src/components/dashorm/DashTreeLayout6.js" /* webpackChunkName: "component---src-components-dashorm-dash-tree-layout-6-js" */),
  "component---src-components-dashorm-dash-tree-layout-7-js": () => import("./../../../src/components/dashorm/DashTreeLayout7.js" /* webpackChunkName: "component---src-components-dashorm-dash-tree-layout-7-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-tests-class-tree-example-test-js": () => import("./../../../src/pages/devTests/classTreeExampleTest.js" /* webpackChunkName: "component---src-pages-dev-tests-class-tree-example-test-js" */),
  "component---src-pages-dev-tests-mega-menu-one-level-test-js": () => import("./../../../src/pages/devTests/MegaMenuOneLevelTest.js" /* webpackChunkName: "component---src-pages-dev-tests-mega-menu-one-level-test-js" */),
  "component---src-pages-dev-tests-multi-ontology-test-js": () => import("./../../../src/pages/devTests/MultiOntologyTest.js" /* webpackChunkName: "component---src-pages-dev-tests-multi-ontology-test-js" */),
  "component---src-pages-dev-tests-simple-app-menu-test-js": () => import("./../../../src/pages/devTests/simpleAppMenuTest.js" /* webpackChunkName: "component---src-pages-dev-tests-simple-app-menu-test-js" */),
  "component---src-pages-download-page-js": () => import("./../../../src/pages/downloadPage.js" /* webpackChunkName: "component---src-pages-download-page-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

