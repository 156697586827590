import getSlug from '../../../componentUtils/getSlug'
import menuDivider from '../../../dashcmm/menuTreeHelper/menuDivider'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import IconDashboard from '@mui/icons-material/Dashboard'
import node2menuItem from './node2menuItem.js'

// @TODO: merge this config with gatsby/src/components/dashcmm/menuTreeHelper/docsDefaultTree.js
const downloadMenu = {
  type: 'menuItem',
  name: 'Téléchargement',
  link: '/downloadPage/',
  Icon: CloudDownloadIcon,
}

const homePageMenu = {
  type: 'menuItem',
  name: 'Accueil',
  link: '/',
  Icon: IconDashboard,
}

const getMenuItem = (name, Icon, link) => {
  return {
    type: 'menuItem',
    name,
    link: getSlug(link, true),
    Icon,
  }
}

export default async function getRdfsMenu(branches) {

  const dynamicContent = []
  for (const br of branches) {
    dynamicContent.push(menuDivider)

    const { nodes, title, icon, fallbackPage } = br

    const branchNode = getMenuItem(title, icon, fallbackPage)
    branchNode.children = await Promise.all(nodes.map(node2menuItem))
    dynamicContent.push(branchNode)
  }

  return {
    type: 'root',
    children: [
      homePageMenu, ...dynamicContent, menuDivider, downloadMenu]
  }
}

