import React from 'react'

import { Link } from 'gatsby'

import { alpha } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button'
import SvgIcon from '@mui/material/SvgIcon'
import Box from '@mui/material/Box'
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'

import useInfiniteScroll from 'react-infinite-scroll-hook'

const sxItemIcon = {
  color: 'primary.main',
  minWidth: '30px',
}

// this is for Gatsby Link in Mui List integration. see: https://mui.com/guides/routing/
const GatsbyLink = React.forwardRef((props, ref) => (
  <Link ref={ref} {...props} activeStyle={{ color: "red" }} role={undefined} />
));

export default function AppMenuItem(props) {
  const { type, name, link, Icon, open: defaultOpen, children = [] } = props.node

  const isOpen = defaultOpen ? true : false
  const [open, setOpen] = React.useState(isOpen)

  if (type === 'menuDivider') return (<Divider />)
  if (type === 'menuSubHeader') return (<ListSubheader inset>{name}</ListSubheader>)

  // other cases : type = 'menuItem'
  const isExpandable = children && children.length > 0
  function handleClick() {
    setOpen(!open)
  }

  const switchButtonConf = {
    isExpandable, open, handleClick,
    ExpandMore: (props) => <PlusSquare {...props} />,
    ExpandLess: (props) => <MinusSquare {...props} />,

  }

  const linkConf = link ? { component: GatsbyLink, to: link } : {}

  const MenuItemRoot = (
    <ListItem
      key={name}
      disablePadding
    >
      <ListItemButton {...linkConf} sx={{ paddingLeft: '5px' }}>
        {!!Icon && (
          <ListItemIcon sx={sxItemIcon}>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText id={name} primary={name} />
      </ListItemButton>

      {isExpandable &&
        <ListItemSecondaryAction>
          <SwitchButton {...switchButtonConf} />
        </ListItemSecondaryAction>
      }

    </ListItem>

  )

  const MenuItemChildren = isExpandable ? ChildItems(open, children) : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}

//// * SwitchButton extract & others graphic options like below
function SwitchButton(props) {
  const {
    isExpandable,
    open,
    handleClick,
    ExpandMore = (props) => <IconExpandMore {...props} />,
    ExpandLess = (props) => <IconExpandLess {...props} />,
  } = props
  return (
    <Button onClick={handleClick}>
      {isExpandable && !open && <ExpandMore {...props} />}
      {isExpandable && open && <ExpandLess {...props} />}
    </Button>

  )
}

const squaresConf = {
  fontSize: 'inherit',
  style: { width: 20, height: 20 }
}
function MinusSquare() {
  return (
    <SvgIcon {...squaresConf}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare() {
  return (
    <SvgIcon {...squaresConf}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

//// end switch button to extract

function ChildItems(open, children) {
  const maxVisibleItems = 50
  const childZoneHeight = 1200
  // @TODO: study this another option: https://mui.com/components/lists/#virtualized-list
  //      actual solution used: react-infinite-scroll-hook
  const { loading, items, hasNextPage, error, loadMore } = useLoadItems(children, maxVisibleItems)
  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
  })

  // good documentation on sx usage: .MuiCollapse-wrapper
  const sxCollapse = {
    '& .MuiCollapse-wrapper': {
      paddingLeft: '15px',
    }
  }
  const sxBox = {
    borderLeft: `2px dashed ${alpha('#3f51b5', 0.4)}`,
  }
  if (hasNextPage) Object.assign(sxBox, { maxHeight: childZoneHeight, overflow: 'auto' })

  return (
    <Collapse in={open} timeout="auto" unmountOnExit sx={sxCollapse}>
      <Box
        sx={sxBox}
        ref={rootRef}
      >
        <Divider variant="inset" />
        {items.map((item, index) => (
          <AppMenuItem node={item} key={index} />
        ))}
        {hasNextPage && (
          <ListItem ref={infiniteRef}>
            <Loading />
          </ListItem>
        )}
      </Box>
    </Collapse>
  )

}

///****** partie liée à la gestion du loading */

function useLoadItems(appMenuItems, maxVisibleItems) {
  const [loading, setLoading] = React.useState(false)
  const [items, setItems] = React.useState([])
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const [error, setError] = React.useState()

  async function loadMore() {
    setLoading(true);
    try {
      const { data,
        hasNextPage: newHasNextPage
      } = await loadItems(items.length, appMenuItems, maxVisibleItems)
      setItems((current) => [...current, ...data])
      setHasNextPage(newHasNextPage)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  return { loading, items, hasNextPage, error, loadMore }
}

async function loadItems(currentLength, fullItems, padding) {
  if (currentLength >= fullItems.length) return { data: [], hasNextPage: false }
  const data = fullItems.slice(currentLength, currentLength + padding)
  const hasNextPage = true
  return { data, hasNextPage }
}



function Loading() {
  return <div>Chargement...</div>;
}

//****** */
