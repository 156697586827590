import { createTheme } from '@mui/material/styles'

let theme = createTheme({}) // 1st create a default theme to be able to create new entries with theme functions (like theme.spacing())

theme = createTheme(theme, {
  palette: {
    primary: {
      main: '#e1001a'
    },
    secondary: {
      main: 'rgba(219, 219, 219, 0.75)'
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
  },
  // components: {
  //   // Name of the component
  //   MuiButton: {
  //     defaultProps: {
  //       style: {
  //         color: "black",
  //       },
  //     },
  //   },
  // }
})

export default theme
