import getStringValues from '../jsonldUtils/getStringValues.js'

// @TODO: add language management here:
export default function getEntityLabel(node, idMode = false){
  const defaultName =  '__default_name_value__'
  let name = defaultName
  if (typeof node === 'string') return node
  // from lowest to highest priority properties if multiple are valuated
  if (node.title) name = getStringValues(node.title)[0]
  if (node.label) name = getStringValues(node.label)[0]
  if (node.prefLabel) name = getStringValues(node.prefLabel)[0] // for skos:ConceptScheme
  if(name === defaultName || idMode) name = node.id
  return name
}
