import AllOut from '@mui/icons-material/AllOut'
import getRdfsMenu from './getRdfsMenu.js'
import getEntityLabel from '../../../../EntityUtils/getEntityLabel.js'

export default async function getMultiOntoMenu(ontologies) {

  // 1/ create a "branch" for each ontology : this is a top level item
  const topLevelItems = ontologies.map(onto => {
    console.log('@TODO: debug for owl:Ontology.label in shacl for example ==> solution is declaration in `createSchemaCustomization`')
    const title = getEntityLabel(onto)
    // define the following nodes
    const nodes = onto.index ? onto.index : []
    if (!nodes) console.warn('This ontology d\'ont have index of entities :', title)
    // @TODO: extract the function `getIconFromName` from `node2menuItem`
    const icon = AllOut
    // @TODO: add an ontology page type, for now it the first record of the first index
    const fallbackPage = onto.id  // null // 'hrrdf:AssessmentSubjectType' //onto?.index?[0]?.records?[0].id
    return {
      title,
      icon,
      fallbackPage,
      nodes,
    }
  })


  return getRdfsMenu(topLevelItems)
}
