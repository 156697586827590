import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import DashMenu from './DashMenu.js'
import getMultiOntoMenu from './menuBuilderUtils/getMultiOntoMenu.js'

export default function MultiOntologiesMenu({ description, lang, meta, title }) {
  const [menuTree, setMenuTree] = useState([])
  const { allOwlOntology } = useStaticQuery(
    graphql`
    fragment rdfs_ClassFlatHierarchy on rdfs_Class {
      id
      label {
        _language
        _value
      }
      hasSubClasses {
        id
      }
      subClassOf {
        id
      }
    }

    fragment skos_ConceptFlatHierarchy on skos_Concept {
      id
      prefLabel {
        _language
        _value
      }
      broader {
        id
      }
      narrower {
        id
      }
    }

    fragment rdf_PropertyFlatHierarchy on rdf_Property {
      id
      label {
        _language
        _value
      }
    }

    query multiOntologiesMenuQuery {
      allOwlOntology {
        nodes {
          id
          inScheme
          type
          types
          comment {
            _language
            _value
          }
          title {
            _language
            _value
          }
          index {
            id
            title {
              _language
              _value
            }
            icon
            type
            types
            records {
              id
              ... on skos_Collection {
                id
                label {
                  _language
                  _value
                }
                comment {
                  _language
                  _value
                }
              }
              ... on skos_ConceptScheme {
                id
                title {
                  _language
                  _value
                }
                description {
                  _language
                  _value
                }
                hasInScheme {
                  id
                  ...rdfs_ClassFlatHierarchy
                  ...rdf_PropertyFlatHierarchy
                  ...skos_ConceptFlatHierarchy
                }
              }
            }
          }
        }
      }
    }
    `
  )

  // for async function call
  useEffect(() => {
    async function getMenuTree() {
      const menuTree = await getMultiOntoMenu(allOwlOntology.nodes) // getRdfsMenu(branches)
      setMenuTree(menuTree)
    }
    getMenuTree()
    // do not really understand this for now.
    //   documentation : https://bobbyhadz.com/blog/react-hook-useeffect-has-missing-dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DashMenu menuTree={menuTree} />
  )
}
