import React from 'react'
import AppMenu from '../../dashibiden/dynamicMenu/AppMenu.js'

export default function DashMenu({ menuTree }) {
  // Trick to limit build size of the generated html : https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => { setHasMounted(true) }, [])
  if (!hasMounted) return (null)

  //@TODO: optimize the rendering with a selection of node and a search function
  //      : also think about the menus levels
  return (
    <>
      <AppMenu tree={menuTree}></AppMenu>
    </>
  )
}
