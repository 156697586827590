import getLocalizedLiterals from './getLocalizedLiterals.js'

export default function getStringValues(propertyValues) {
  if (!propertyValues) return null
  if (propertyValues.length === 1 && propertyValues[0] === null) return null // for array with a null

  // case of properties without array (like Id, DataCube dimensions value,... )
  if (typeof propertyValues === 'string') {
    return [propertyValues]
  }

  if (Array.isArray(propertyValues)) {
    const [firstOne] = propertyValues
    const tof = typeof firstOne
    if (tof === 'string') return propertyValues
    if (tof === 'object') return getLocalizedLiterals(propertyValues)
  }

  return null //this property is from another type
}
