import React from 'react'
import List from '@mui/material/List'
import AppMenuItem from './AppMenuItem'

// This is the main "first level" menu
// general menu structure inspired from : https://medium.com/@modularcoder/reactjs-multi-level-sidebar-navigation-menu-with-typescrip-materialui-251943c12dda

export default function AppMenu({ tree }) {
  const appMenuItems = tree.children

  let content = (<p>No children - to fix</p>)
  if (appMenuItems) {
    content = appMenuItems.map((item, index) => (
      <AppMenuItem node={item} key={index} />
    ))
  }

  return (
    <List component="nav"
      disablePadding
      dense
    >
      {content}

    </List>
  )
}
