import getSlug from '../../../componentUtils/getSlug'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import BubbleChart from '@mui/icons-material/BubbleChart'
import DeviceHub from '@mui/icons-material/DeviceHub'
import AccountTree from '@mui/icons-material/AccountTree'
import AllOut from '@mui/icons-material/AllOut'
import DataObject from '@mui/icons-material/DataObject'
import getEntityLabel from '../../../../EntityUtils/getEntityLabel.js'

export default async function node2menuItem(node) {

  const name = getEntityLabel(node)

  const Icon = node.icon ? await getIconFromName(node.icon) : DoubleArrowIcon

  const menuItem = {
    type: 'menuItem',
    name,
    link: `/${getSlug(node.id)}`,
    Icon,
  }


  // 1/ treeification of "inScheme" nodes (for rdf:Class & skos:Concept)
  if (node.hasInScheme && node.hasInScheme.length) {

    // for Class
    if (node.hasInScheme.some(n => n.subClassOf)) {
      const treefied = treefy(node.hasInScheme, 'subClassOf', 'hasSubClasses')
      node.hasInScheme = treefied
    }

    // for Concept
    if (node.hasInScheme.some(n => n.broader)) {
      const treefied = treefy(node.hasInScheme, 'broader', 'narrower')
      node.hasInScheme = treefied
    }

    menuItem.children = await Promise.all(node.hasInScheme.map(node2menuItem))
  }

  // 2/ processing of node to menutree transformation

  // for rdfs:Class
  if (node.hasSubClasses && node.hasSubClasses.length) {
    menuItem.children = await Promise.all(node.hasSubClasses.map(node2menuItem))
  }

  // for skos:Concept
  if (node.narrower && node.narrower.length) {
    menuItem.children = await Promise.all(node.narrower.map(node2menuItem))
  }


  // for om:OntoIndex
  if (node.records && node.records.length) {
    const { records } = node

    menuItem.children = await Promise.all(records.map(node2menuItem))

    // this is a special case for entities not in schemas if the source,
    // we skip the "generated level"
    if (records.length === 1 && records[0].id.endsWith('_generated')) {
      menuItem.children = menuItem.children[0].children // skip the default level
    }

  }

  return menuItem
}

// This is a uggly solution, but dynamic import cause out of memory error, even with an importCache
// ==> see commented code for dymanic import solution with cache
const iconsList = {
  BubbleChart,
  DeviceHub,
  AccountTree,
  DataObject,
}

// const iconImportCache = {}

async function getIconFromName(iconName) {
  if (typeof iconName === 'function') return iconName

  if (iconsList[iconName]) return iconsList[iconName]
  // try {
  //   if(!iconImportCache[iconName]) iconImportCache[iconName] = (await import(`@mui/icons-material/${iconName}`)).default
  //   return iconImportCache[iconName]
  // } catch (e) {
  //   // do nothing, continue and return the default one
  // }


  console.warn('Icon value not found for', iconName, 'return the default one')
  return AllOut
}


function treefy(nodes, climbRelation, descentRelation) {

  // check if parent node is in the current "scheme" (case of hrrdf where some parents are not in the same scheme)
  const isInScheme = (parents) => parents.some( p => nodes.find(n => n.id === p.id))

  // 1/ extract the roots elements (without climbRelation values)
  const roots = nodes.filter(n =>
    !n[climbRelation] || !n[climbRelation].length
    || !isInScheme(n[climbRelation]) // add items with superclass outside of the current nodes list
    )

  treefy_recurse(roots, nodes, climbRelation, descentRelation)

  return roots
}

function treefy_recurse(entities, nodes, climbRelation, descentRelation) {
  if (!entities.length || !nodes.length) return

  for (const r of entities) {
    if (!r) {
      console.error('!!!!!!!!!!!! entities with null', r, entities)
      throw new Error('Error in this algorithm: a null entitie is returned somewhere')
    }

    const children = r[descentRelation]
    if (!children) {
      continue
    }

    const targetChildren = []
    for (const c of children) {
      const e = nodes.filter(n => n.id === c.id)
      if (!e.length) {
        // console.warn('No node find for this descendant id', c)
        continue
      }
      if (e.length > 1) {
        console.warn('Multiple nodes find with this descendant id', c, 'finded values:', e)
        continue
      }
      targetChildren.push(e[0])
    }



    treefy_recurse(targetChildren, nodes, climbRelation, descentRelation)
    r[descentRelation] = targetChildren
  }
}
